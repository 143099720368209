import React, { useState } from 'react';
import { Part } from './part';
import "./power.css";
import Modal from '../features/modal/modal';
import { addIndex, map as m } from 'ramda';
import Slider from 'react-slick';


export const power_ids = {
    ET: {
        media_title: "TEEMA KAJASTUSED",
        videos_title: "VIDEOD",
        title_id:34,
        text_id:35,
        reports:[{
            id:36,
            publications: [{                
                link:"/uploads/2024/08/Praxis. Tippjuhiks saamise teed - mehed juhtuvad juhiks, naised juhivad end juhiks.pdf",
                title: "PRAXISE UURINGU RAPORT"
            }]
        },{
            id:37,
            publications: [{
                link:"/uploads/2024/08/Praxis. Nais- ja meesjuhtide meediakajastuste ja sotsiaalmeediakasutuse analüüs.pdf",
                title:"MEEDIAANALÜÜSI RAPORT"
            }]
        }],
        videos:[45,39,38],
        news_ids: [102,40,41,42,43],
        logos_id:44
    },
    EN: {
        media_title: "PUBLICITY",
        videos_title: "VIDEOS",
        title_id:46,
        text_id:47,
        reports:[{
            id:48,
            publications: [{
                link:"/uploads/2024/09/Summary Praxis - Power of Women Leaders.pdf",
                title: "Summary in English"
            },{
                link:"/uploads/2024/08/Praxis. Tippjuhiks saamise teed - mehed juhtuvad juhiks, naised juhivad end juhiks.pdf",
                title: "Full Report in Estonian"
            }]
        },{
            id:49,
            publications: [{
                link:"/uploads/2024/09/Summary Praxis - media analysis.pdf",
                title: "Summary in English"
            },{
                link:"/uploads/2024/08/Praxis. Nais- ja meesjuhtide meediakajastuste ja sotsiaalmeediakasutuse analüüs.pdf",
                title:"Full Report in Estonian"
            }]
        }],
        videos:[87,85,86],
        news_ids: [50,51,52],
        logos_id:53
    },
} 


const Video = ({ src, poster, onClick }) => (
    <img
        src={poster}
        alt="Video poster"
        style={{ cursor: 'pointer', width: '100%' }}
        onClick={onClick}
    />
);

export const Power = ({title_id, text_id, reports,videos,news_ids,logos_id,media_title,videos_title}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [videoSrc, setVideoSrc] = useState('');
    const [videoPoster, setVideoPoster] = useState('');
    const settings = {dots: false,infinite: false,speed: 500,slidesToShow: 3,slidesToScroll: 1,arrows: true,
        responsive: [
            {breakpoint: 600,settings: {slidesToShow: 1,}},
            {breakpoint: 900,settings: {slidesToShow: 2,}},
        ]}

    const handleVideoClick = (src, poster) => {
        setVideoSrc(src);
        setVideoPoster(poster);
        setIsModalOpen(true);
    };

    return <>
        <div className="power-intro introduction">
            <Part part_id={title_id} className="full_width"/>
            <Part part_id={text_id}/>
        </div>
        {m(({id,publications}) => <div className="power-job-card" key={"report_"+id}>
            <img src="/uploads/2024/08/grain_pattern.png" decoding="async" loading="lazy" className="pattern" />
            <Part part_id={id}/>
            {addIndex(m)(({link,title},n) => 
                <a href={link} key={"publication_"+id+"_"+n} target="_blank" rel="noopener noreferrer">{title}</a>,publications)}
        </div>,reports)}
        <div className="power-full-width" id="videod">
            <div>
                <h2>{videos_title}</h2>
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <video width="100%" controls autoPlay poster={videoPoster}>
                        <source src={videoSrc} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Modal>
                <div className="three-col no-padding videos">
                    {m(id => <Part key={"part_"+id} part_id={id} components={{
                        video: ({node, ...props}) => 
                            <Video {...props} onClick={() => handleVideoClick(props.src, props.poster)} />,
                    }}/>,videos)}
                </div>
            </div>
        </div>
        <div className="slider-container power-media" id="meedia">
            <h2>{media_title}</h2>
            <Slider {...settings}>
                {addIndex(m)((id,n) => 
                    <Part part_id={id} key={"media_"+id} components={{a: ({href,children}) => 
                        <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>}}/>
                ,news_ids)}
            </Slider>
        </div>
        <div className="power-logod">
            <Part part_id={logos_id} />
        </div>
    </>
}